<template lang="pug">
form.support(@submit.prevent="login")
  AppFormInput(type="select" name="type" :label="$t('support.type')" :options="types")
  AppFormInput(type="textarea" name="description" :label="$t('support.description')" class="mb-2")
  AppButton(:loading="loading" :label="$t('support.send')")
</template>

<script>
import { ref } from "vue";
import AppFormInput from "@/components/Form/AppFormInput";
import AppButton from "@/components/AppButton";
import { useI18n } from "vue-i18n";

export default {
  components: {
    AppFormInput,
    AppButton,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(false);
    const types = [
      {
        value: "1",
        label: t("support.types.service_failures"),
      },
      {
        value: "2",
        label: t("support.types.payments"),
      },
      {
        value: "3",
        label: t("support.types.plans"),
      },
    ];

    return { loading, types };
  },
};
</script>
